<template>
  <div class="w-full h-full all pt-4">
    <div class="sous-titre w-full mt-4 mb-3">
      Numero de plaque
    </div>
    <plaque-input :donnes="donnes" @info="retourPlaque"/>

    <div class="sous-titre w-full mt-4">
      Date de mise en circulation
    </div>
    <datePicker class="mt-3" :deseable="['afterToday']" @info="retourDate"/>

    <div class="sous-titre w-full mt-4 mb-3">
      Zone de circulation
    </div>
    <inputo :value="zone" @info="retourZone" placeholder="Écrire pour rechercher"/>
    <selectListe :option="allZone" @info="retourSearch" :value="zone" v-if="zone.length > 0 && selectedZone !== zone"/>

    <div class="sous-titre w-full mt-4 mb-3">
      Quelle est votre statut socio-professionnel?
    </div>
    <multiselect :option="allStatus" :write-option="true" @info="retourStatus" :value="selectedStatus"/>

    <div class="sous-titre w-full mt-4">
      Date d’effet du contrat
    </div>
    <datePicker v-if="exept !== null" class="mt-3" :deseable="['beforeToday', new Date().toLocaleDateString()]" @info="retourSouscription"/>

    <div class="sous-titre w-full mt-4 mb-3">
     Numero de la pièce d'identité
    </div>
    <inputo @info="retourCard" :value="card" placeholder="Ex: 123456"/>

    {{donnee}}
    {{controleur}}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import datePicker from '@/components/helper/form/datePicker'
import selectListe from '@/components/helper/add/selectListe'
import localite from '@/components/cedro/jsonWord/locality.json'
import multiselect from '@/components/helper/form/multiselect'
import status from '@/components/cedro/jsonWord/social-status.json'
import plaqueInput from '@/components/helper/form/plaqueInput'

export default {
  name: 'index',
  components: {
    inputo,
    datePicker,
    selectListe,
    multiselect,
    plaqueInput
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      position: 'oui',
      date: null,
      souscriptionDate: null,
      zone: '',
      allZone: [],
      allStatus: [],
      selectedZone: null,
      selectedStatus: 'Autres',
      plaque: '',
      card: null,
      exept: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      if (this.card !== null && this.date !== null && this.plaque !== '' && this.souscriptionDate !== null && this.selectedStatus !== 'Autres' && this.selectedZone !== null) {
        const obj = {
          statusSocial: this.selectedStatus,
          zone: this.selectedZone,
          dateMiseEnCirculation: new Date(this.date).getTime(),
          plaque: this.plaque,
          souscriptionDate: new Date(this.souscriptionDate).getTime(),
          cardId: this.card
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.trackPays()

    const now = new Date().toLocaleDateString()
    this.exept = now.substr(3, 3) + now.substr(0, 3) + now.substr(6, 4)

    if (this.donnes.autre !== undefined) {
      this.donnes.plaque = this.donnes.autre.plaque
      this.zone = this.donnes.autre.zone
      this.selectedStatus = this.donnes.autre.statusSocial
      this.card = this.donnes.autre.cardId
    }
  },

  methods: {
    retourCard (answer) {
      this.card = answer
    },

    retourPlaque (answer) {
      this.plaque = answer
    },

    isNumberOnly ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      if ((keyCode < 42 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault()
      }
    },

    selected (index) {
      this.position = index
    },

    retourDate (answer) {
      this.date = answer
    },

    retourSouscription (answer) {
      this.souscriptionDate = answer
    },

    retourZone (answer) {
      this.zone = answer
    },

    retourStatus (answer) {
      this.selectedStatus = answer
    },

    retourSearch (answer) {
      this.selectedZone = answer
      this.zone = answer
      this.$emit('info', answer)
    },

    trackPays () {
      for (const item in localite) {
        this.allZone.push(localite[item].label)
      }

      for (const item in status) {
        this.allStatus.push(status[item].label)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sous-titre{
  font-family: 'Mulish', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: #000000;
}
.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}
.non{
  background-color: $base-color;
  color: white;
}
.alerte{
  height: 86px;
  border-radius: 20px;
  background-color: #F6E4E5;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: black;
}
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}

.tiret{
  font-weight: 500;
  font-size: 20px;
  color: #A9B2C6;
  letter-spacing: 8.5px;
}

.bas{
  height: 2px;
  margin-top: -10px;
}
@media screen and (max-width: 700px){
  .sous-titre{
    font-size: 16px;
  }
  .button{
    width: 30%;
    font-size: 17px;
  }
}
</style>
