<template>
  <div class="w-full h-full">
    <div
      class="flex flex-wrap justify-center mt-20 all"
    >
      <div class="marque w-1/6 ml-2 mr-4 mb-5" v-for="(item, index) in allInfo"
           :key="index">
        <marque-liste
          class="" :index="index" :icone="item" :position="position"
          @oga="retourMarque"
        />
      </div>
    </div>

    <div class="sel pl-5 pr-5 mt-5">
      <div class="w-full mr-3">
        <multiselect :option="autre" :write-option="true" :value="autreValue" @info="retourAutre"/>
      </div>
    </div>
  </div>
</template>

<script>
import marqueListe from '@/components/helper/form/marqueListe'
import haojue from '../../../assets/images/png/haojue.png'
import dayang from '../../../assets/images/png/dayang.png'
import rato from '../../../assets/images/png/rato.png'
import bajaj from '../../../assets/images/png/bajaj.png'
import honda from '../../../assets/images/png/hondaMotor.png'
import tvs from '../../../assets/images/png/tvs.png'
import suzuki from '../../../assets/images/png/suzuki.png'
import yamaha from '../../../assets/images/png/yamaha.png'
import multiselect from '@/components/helper/form/multiselect'
import allCar from '../jsonWord/motos.json'

export default {
  name: 'index',
  components: {
    marqueListe,
    multiselect
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allInfo: [],
      autre: [],
      position: -1,
      value: null,
      autreValue: 'Autres'
    }
  },

  created () {
    this.trackImage()
    console.log(this.donnes)
  },

  methods: {
    retourMarque (answer) {
      this.position = answer
      this.value = this.allInfo[answer].name
      this.autreValue = 'Autres'
      this.$emit('info', this.value)
    },

    openOption () {
      this.activeOption = !this.activeOption
    },

    retourAutre (answer) {
      if (answer !== 'Autres') {
        this.autreValue = answer
        this.position = -1
        this.$emit('info', this.autreValue)
      }
    },

    trackImage () {
      for (const item in allCar) {
        if (allCar[item].name === 'Haojue') {
          allCar[item].image = haojue
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'Honda') {
          allCar[item].image = honda
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'Dayang') {
          allCar[item].image = dayang
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'Rato') {
          allCar[item].image = rato
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'Suzuki') {
          allCar[item].image = suzuki
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'Yamaha') {
          allCar[item].image = yamaha
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'TVS') {
          allCar[item].image = tvs
          this.allInfo.push(allCar[item])
        } else if (allCar[item].name === 'Bajaj') {
          allCar[item].image = bajaj
          this.allInfo.push(allCar[item])
        } else {
          this.autre.push(allCar[item].name)
        }

        if (this.donnes !== null && this.donnes.marque !== undefined) {
          if (allCar[item].name === this.donnes.marque) {
            this.position = parseInt(item)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.marque{
  width: 146.28px;
  height: 144.76px;
}
.sel{
  width: 99%;
}
.champ{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: $rubik;
  color: #909090;
  height: 70px;
}
@media screen and (max-width: 700px) {
  .marque{
    width: 100px;
    height: 121px;
  }
  .mt-20{
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px){
  .mt-20{
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 770px){
  .mt-20{
    margin-top: 40px !important;
  }
}
</style>
