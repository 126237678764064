<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class="w-full mb-3"
        >
          <radio
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'

export default {
  name: 'index',
  components: {
    radio
  },
  props: {
    donnes: {
      type: String,
      default: null
    },
    responses: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allInfo: [],
      position: 'Jusqu\'à 75 cc'
    }
  },
  created () {
    if (this.donnes.substr(0, 1).toLowerCase() === 'c') {
      this.allInfo.push('Jusqu\'à 75 cc')
    } else if (this.donnes.substr(0, 1).toLowerCase() === 'v') {
      this.allInfo.push('Jusqu\'à 125 cc')
    } else if (this.donnes.substr(0, 1).toLowerCase() === 't') {
      this.allInfo.push('0 à 125 cc')
    } else if (this.donnes.substr(0, 1).toLowerCase() === 's') {
      this.allInfo.push('Jusqu\'à 250 cc')
    } else if (this.donnes.substr(0, 1).toLowerCase() === 'm') {
      this.allInfo.push('0 à 2 CV')
      this.allInfo.push('3CV et plus')
    }
    this.position = this.allInfo[0]
    this.$emit('info', this.position)

    if (this.responses.puissance !== undefined) {
      this.position = this.responses.puissance
    }
  },
  methods: {
    retourInfo (answer) {
      this.position = answer
      this.$emit('info', answer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
</style>
